import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Img } from '../../../../../Styles/Maintext.styles'; 
import { Box, Button, Link } from '@mui/material';
import Paragraph from '../../../../Paragraph'; 

  export default function MenuButtons(props) {  
   
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick} 
        sx={{ 
          display:"flex", 
          flexDirection: "row", 
          justifyContent: "center",
         alignItems: "center",  
        //  width:"50px",  
         width:{xs:"204px",lg:"280px"},
         textTransform:'none',
        //  minWidth:{xs:"30px !important",lg:"64px"},
         gap:{xs:"2px",lg:"2px"},    
         borderRadius:"6px",
         background :"linear-gradient(110deg,#663CED 30%,#FFA5FD)",
         ":hover" :{  
           backgroundColor:"transparent"
         } ,   
         padding:{xs:"2px 20px 2px 15px",lg:"1px 50px 1px 40px"},
         position:"relative",
         zIndex:"999", 
        }}
      >   
       <Img src={props.src} alt="images" w={props.w} small_w={props.small_w} />  
       <Box display="flex" flexDirection="column" justifyContent="start" alignItems="start"> 
       <Paragraph variant="body2" fontSize={{xs:"12px",lg:"16px"}} lineHeight={{xs:"0px",lg:"20px"}} fontWeight="600" textAlign="left" sx={{whiteSpace:"nowrap"}}  color="white">Download For {props.name}</Paragraph>
       {/* <Paragraph variant="body2" fontSize="17px" lineHeight={{xs:"",lg:"20px"}} fontWeight="600" textAlign="center" color="white">{props.name}</Paragraph> */}
       </Box>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      > 
      {props.array.map((item) => (
     <Link
      href={item.link}
      target={item?.target}
      underline="none"
      color="inherit"
      key={item.title}
     >
          <MenuItem sx={{border:"1px solid #707070", borderRadius:"6px",width:{xs:"150px !important"}}} onClick={handleClose}> 
           {item.title}
          </MenuItem>
     </Link>
     ))}
      </Menu>
    </div>
  );
}
